import React from 'react';
import './footerslide.css';
import N from "../../assets/N.png";
import V from "../../assets/V.png";
import Nik from "../../assets/Nik.png";
import logo from "../../assets/logo.svg";
import {useSpring, animated} from "react-spring";

function FooterSlide() {
    const abobaStyle = useSpring({
        loop: true,
        from: {transform: `rotate(0deg)`},
        to: [
            {transform: `rotate(359deg)`},
        ],
    })

    const [abobaClicked, setAbobaClicked] = React.useState(false);

    //слайд футера

    return (

        <section className="section">
            <div className="container container-common h-100">
                <div className="d-flex flex-column align-items-center h-100 justify-content-between">
                    <div className="flex-grow-1">
                        <div className="d-flex h-100 flex-column align-items-center justify-content-center">
                            <div className="text--end text-center">
                                Заинтересован? <br/>
                                Тогда подавай <br/>
                                документы <br/>
                                до 26 июля <br/>
                                в политехе
                                или дистанционно
                            </div>

                            <a href="https://int.istu.edu/local/mod/rpd_files/download.php?id=327551" target="_blank" className="btn btn--end">Скачать учебный план</a>
                            
                            <a href="https://cis.istu.edu/pub/auth" target="_blank" className="btn btn--end--two">Подать
                                документы <br/> <span className="text-mini--btn">на направление 09.03.02</span></a>
                            
                            <a href="mailto:arshinskyv@istu.edu" target="_blank" className="btn btn--end--two" >Связаться с руководителем программы</a>

                        </div>
                    </div>

                    <div className="mb-4">
                        <div className="row">
                            <div className="col text-center">
                                <div className="text-mini">
                                    Разработано &nbsp;<br/>
                                    <a href="http://www.istu.edu/deyatelnost/obrazovanie/instituty/iit/cpi/default" target="_blank">
                                        Центром программной инженерии ИРНИТУ
                                    </a>
                                </div>
                            </div>
                        </div>

                        
                    </div>

                </div>
            </div>
        </section>
    )
}

export default FooterSlide;