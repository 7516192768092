import React from 'react';
import './infoslide.css';

function InfoSlide() {
    return (
        <section className="section work--info--slide">
            <div className="wrapper container container-common h-100">

                <div className="title--info">
                    <h2>
                        Аннотация
                    </h2>
              
                </div>
                    <div className="info--text">Программа ориентирована на изучение подходов к разработке информационных систем и технологий применительно к задачам управления и поддержки принятия решений.</div>
                    <div className="info--text">Основными направлениями являются системный анализ, автоматизация организационных и производственных процессов, разработка и внедрение программного обеспечения для бизнеса.</div>
                    <div className="info--text">Обучение по программе включает в себя изучение теоретических основ и приобретение практических навыков разработки программного обеспечения, создания и эксплуатации информационных систем и технологий, предназначенных для решения разнообразных задач: от автоматизации бизнес-процессов, до разработки мобильных и web-приложений и создания информационных технологий поддержки принятия решений.</div>
                    <div className="info--text">Студенты учатся применять языки (C, C++, C#, Java, PHP, JavaScript, Python, Kotlin) и технологии программирования, в том числе web-технологии, технологии анализа данных и искусственного интеллекта.</div>
                    <div className="info--text">Уникальное сочетание навыков в области разработки информационных систем, анализа данных, технологий программирования, проектирования баз данных и систем поддержки принятия решений, дополненное знаниями в области искусственного интеллекта и управления.</div>
               </div>
        </section >
    )
}

export default InfoSlide;